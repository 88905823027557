<template>
  <div>
    <a-spin :spinning="loading">
      <p style="color: red;">此配置用于云端系统出票座位，在开场前后自动释放座位。自动释放的座位不影响售后。</p>
      <a-form ref="form" :model="formState" name="form" @finish="onSearch">
        <a-row>
          <a-form-item class="ui-form__item" name="organizationId" label="影院组织">
            <a-select placeholder="请选择影院组织" v-model:value="formState.organizationId" style="width: 250px;"
                      @change="getAllCinemaList">
              <!-- <a-select-option :value="0">全部</a-select-option> -->
              <a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id">
                {{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
            <a-select placeholder="请选择所属影院" v-model:value="formState.cinemaId" style="width: 250px;">
              <a-select-option :value="0">全部</a-select-option>
              <a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
                {{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item class="ui-form__item" label="影片名称" name="filmName">
            <a-input placeholder="请输入影片名称" v-model:value="formState.filmName"></a-input>
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
<!--            <a-button type="primary" @click="onAdd">批量释放</a-button>-->
          </a-col>
          <a-col :span="6" style="text-align: right;">
            <a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>

      <div style="margin-top: 20px;">
        <a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list">
          <template #bodyCell="{ column, record }">
            <template v-if="column.dataIndex === 'releaseType'">
              {{ record.releaseType == 2 ? '手动释放' : '自动释放' }}
            </template>
            <template v-if="column.dataIndex === 'showStartTime'">
              {{ transDateTime(record.showStartTime) }}
            </template>
            <template v-if="column.dataIndex === 'releaseTime'">
              {{ transDateTime(record.releaseTime) }}
            </template>
          </template>
        </a-table>
      </div>
    </a-spin>
  </div>
</template>

<script>
  import {
    Icon
  } from '@/components/icon/icon.js';
  import {
    getOrganizationList,
    getCinemaList,
    changeCinemaFilmMode,
    deleteCinemaFilmMode, getReleaseSeatsList
  } from '@/service/modules/cinema.js';
  export default {
    components: {
      Icon,
    },
    data() {
      return {
        loading: false,
        showAll: false,
        organizationList: [],
        cinemaAllList: [],
        time: [],
        formState: {
          buyTicketMode: '',
          isDisabled: '',
          organizationId: 0,
        },
        searchData: {},
        list: [],
        columns: [{
          title: '所属影院',
          dataIndex: 'cinemaName'
        }, {
          title: '影片名称',
          dataIndex: 'filmName',
        }, {
          title: '放映时间',
          dataIndex: 'showStartTime',
        }, {
          title: '释放方式',
          dataIndex: 'releaseType',
        }, {
          title: '释放时间',
          dataIndex: 'releaseTime',
        }],
        pagination: {
          showSizeChanger: true,
          showQuickJumper: true,
          size: "middle",
          showTotal: (total) => {
            return "共 " + total + " 条";
          },
          total: 0,
          current: 1,
          pageSize: 10,
          onChange: (page) => {
            this.pagination.current = page;
            this.getData();
          },
          onShowSizeChange: (page, pageSize) => {
            this.pagination.current = 1;
            this.pagination.pageSize = pageSize;
            this.getData();
          }
        },
        showModal: false,
        isEdit: false,
        isSee: false,
        id: 0
      }
    },
    computed: {
      today() {
        return this.moment().format('YYYY.MM.DD')
      },
      twoDayAgo() {
        return this.moment().subtract().format('YYYY.MM.DD')
      }
    },
    created() {
      this.getOrganizationList();
      this.onSearch();
    },
    methods: {
      onBack(isRef) {
        this.showModal = false;
        if (isRef) {
          // this.getData();
        }
      },
      onSearch() {
        this.pagination.current = 1;
        // this.pagination.pageSize = 10;
        this.searchData = JSON.parse(JSON.stringify(this.formState));
        this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
        this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
        this.searchData.buyTicketMode = this.searchData.buyTicketMode ? this.searchData.buyTicketMode : undefined;
        this.searchData.isDisabled = this.searchData.isDisabled !== '' ? this.searchData.isDisabled : undefined;
        this.getData();
      },
      reset() {
        this.$refs.form.resetFields();
        this.onSearch();
      },
      onSubmit() {
        this.$refs.addForm.validateFields().then(async () => {
          this.loading = true;
          try {
            let postData = {
              ...this.modelRef
            }
            let ret = await updateFilmMode(postData);
            this.loading = false;
            if (ret.code === 200) {
              this.$message.success('操作成功');
              this.$refs.addForm.resetFields();
              this.showModal = false;
              this.modelRef = {};
              this.getData();
            }
          } catch (e) {
            this.loading = false;
          }
        })
      },
      onCancel() {
        this.$refs.addForm.resetFields();
        this.modelRef = {};
        this.showModal = false;
      },
      async getData() {
        this.loading = true;
        try {
          let ret = await getReleaseSeatsList({
            page: this.pagination.current,
            pageSize: this.pagination.pageSize,
            ...this.searchData
          })
          this.loading = false;
          if (ret.code === 200) {
            this.pagination.total = ret.data.totalCount;
            this.list = ret.data.list;
          }
        } catch (e) {
          this.loading = false;
        }
      },
      async getOrganizationList() {
        let ret = await getOrganizationList({
          page: 1,
          pageSize: 999999
        });
        if (ret.code === 200) {
          this.organizationList = ret.data.list;
          if (this.organizationList.length) {
            this.formState.organizationId = this.organizationList[0].id;
            this.getAllCinemaList(this.formState.organizationId);
          }
        }
      },
      async getAllCinemaList(organizationId) {
        this.cinemaAllList = [];
        this.formState.cinemaId = 0;
        let ret = await getCinemaList({
          page: 1,
          pageSize: 999999,
          organizationId: organizationId ? organizationId : undefined
        })
        if (ret.code === 200) {
          this.cinemaAllList = ret.data.list;
        }
      },
      onDisabled(record) {
        this.$confirm({
          title: '提示',
          content: `确定${ record.isDisabled ? '启用' : '禁用' }该配置吗？`,
          onOk: async () => {
            this.loading = true;
            let ret = await changeCinemaFilmMode({
              id: record.id,
              isDisabled: record.isDisabled ? 0 : 1
            });
            this.loading = false;
            if (ret.code === 200) {
              this.$message.success(`${ record.isDisabled ? '启用' : '禁用' }成功！`)
              this.getData();
            }
          }
        })
      },
      onDelete(record) {
        this.$confirm({
          title: '提示',
          content: '确定删除该配置吗？',
          onOk: async()=> {
            this.loading = true;
            let ret = await deleteCinemaFilmMode({
              id: record.id
            })
            this.loading = false;
            if (ret.code === 200) {
              this.$message.success('删除成功！')
              this.getData();
            }
          }
        })
      },
      onAdd() {
        this.id = 0;
        this.isEdit = false;
        this.isSee = false;
        this.showModal = true;
      },
      onEdit(item) {
        this.id = item.id;
        this.isEdit = true;
        // this.isSee = false;
        // this.editData = item;
        this.showModal = true;
      },
      beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          this.$message.error('上传文件格式不正确');
        }
        const isLt1M = file.size / 1024 / 1024 < 2;
        if (!isLt1M) {
          this.$message.error('上传文件太大了');
        }
        return isJpgOrPng && isLt1M;
      },
      handleChange(info) {
        if (!info.file.status) {
          this.fileList.splice(this.fileList.length - 1, 1);
        }
        if (info.file.status === 'done') {
          this.modelRef.imgUrl = '';
          const $fileList = [...info.fileList];
          $fileList.map(file => {
            if (file.response) {
              this.modelRef.imgUrl = file.response.data.imgUrl;
              file.url = file.response.data.imgUrl;
            }
            return file;
          });
        }
      },
    }
  }
</script>

<style scoped>
    .ui-form__item {
        margin-right: 30px;
    }
</style>
